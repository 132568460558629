<template>
  <div class="navbar-container d-flex content align-items-center text-black">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none d-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Right Col -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <li class="nav-item nav-search">
        <!-- Icon -->
        <a
          href="https://travelbus.com.co/"
          class="nav-link nav-link-search"
        >
          <b>Inicio</b>
        </a>
      </li>
      <li class="nav-item nav-search">
        <!-- Icon -->
        <a
          href="/viajes"
          class="nav-link nav-link-search"
        >
          <b>Viajes</b>
        </a>
      </li>
      <!-- <horizontal-nav-menu /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
